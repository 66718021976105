/* eslint-disable */

<template>
  <div id="Lgx175726384910572349">
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag">
      <div :class="{ 'cursor-drag': draggingCard === true }" class="vx-row">
        <div class="vx-col lg:w-4/12 md:w-4/12 sm:w-5/5">
          <vx-card class="mb-base">
            <h3 class="font-bold mb-4">IP Address List</h3>
            <div v-for="item of ip_info" class="vx-row" :key="item.id">
              <div class="flex items-center justify-center vx-col w-3/12 p-3">
                <font-awesome-icon
                  icon="fa-sharp fa-solid fa-server"
                  style="background: #181d2a !important"
                  class="h-8 w-8 p-4 rounded-full"
                />
              </div>
              <div class="vx-col w-9/12 p-3">
                <span class="flex flex-row gap-x-2 text-xl font-bold my-1 ubuntu-font"
                  >IP:
                  <p class="font-medium">{{ item.ip }}</p></span
                >
                <vue-draggable-container
                  :id="'input' + item.id"
                  class="answerStandard dropzone dropzoneStandard answer-bg w-full"
                ></vue-draggable-container>
              </div>
            </div>
          </vx-card>
        </div>
        <div class="vx-col lg:w-8/12 md:w-8/12 sm:w-5/5">
          <vue-draggable-container
            id="wordBank"
            class="mb-4 p-4 bg-background wordBank rounded-lg"
            style="border: 1px solid #181d2a; min-height: 132px !important"
          >
            <h5 class="dragBlock font-semibold cursor-pointer text-center h-fit">Network Printer</h5>
            <h5 class="dragBlock font-semibold cursor-pointer text-center h-fit">Cisco Router</h5>
            <h5 class="dragBlock font-semibold cursor-pointer text-center h-fit">Windows Workstation</h5>
            <h5 class="dragBlock font-semibold cursor-pointer text-center h-fit">macOS Laptop</h5>
            <h5 class="dragBlock font-semibold cursor-pointer text-center h-fit">Linux Server</h5>
          </vue-draggable-container>

          <vue-command
            id="command_prompt"
            :commands="commands"
            :prompt="'C:\\Users\\' + activeUserInfo.firstName.toLowerCase().replace(/ /g, '') + '>'"
            :show-intro="true"
            class=""
            intro="Microsoft Windows [Version 10.0.18363.1256] (c) 2019 Microsoft Corporation. All rights reserved."
            style="background-color: #111111; border: 1px solid #181d2a; border-radius: 0.5rem"
            title="Command Prompt"
          />
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';
import VueCommand, { createStdout, createStderr } from 'vue-command';
import 'vue-command/dist/vue-command.css';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },

      ip_info: [
        {
          id: '0',
          ip: '192.168.1.1',
        },
        {
          id: '1',
          ip: '192.168.1.13',
        },
        {
          id: '2',
          ip: '192.168.1.27',
        },
        {
          id: '3',
          ip: '192.168.1.31',
        },
        {
          id: '4',
          ip: '192.168.1.44',
        },
      ],

      commands: {
        help: () => createStdout('Available commands:<br>&nbsp;nmap [args] [target]<br>'),
        nmap(x) {
          if (x.O !== undefined) {
            x.o = x.O;
          }

          if (x.o === undefined) {
            return createStderr('Invalid Usage: nmap [args] [target]');
          }

          if (x.o === '192.168.1.1') {
            return createStdout(`<br><br><br>-----------------------------------------------<br><br>Starting Nmap 7.91 ( https://nmap.org ) at 2021-09-22 10:40 EDT<br>
  Nmap scan report for gateway router (192.168.1.1)<br>
  Host is up (0.0050s latency).<br>
  Not shown: 999 closed ports<br>
  PORT   STATE SERVICE<br>
  23/tcp open  telnet<br>
  | fingerprint-strings:<br>
  |   GenericLines:<br>
  |     Password:******<br>
  |     !<br>
  |     No Access Allowed<br>
  |     !<br>
  |     ?<br>
  |_    login:<br>
  Warning: OSScan results may be unreliable because we could not find at least 1 open and 1 closed port<br>
  Aggressive OS guesses: Cisco 1841 router (96%), Cisco 870 router (96%), Cisco Catalyst 2960 switch (95%), Cisco 2600/2800/3800 router (93%), Cisco 2801 router (93%)<br>
  No exact OS matches for host (test conditions non-ideal).<br>
  Network Distance: 1 hop<br><br>

  OS detection performed. Please report any incorrect results at https://nmap.org/submit/ .<br>
  Nmap done: 1 IP address (1 host up) scanned in 10.27 seconds<br><br>
  -----------------------------------------------<br><br><br>`);
          }

          if (x.o === '192.168.1.13') {
            return createStdout(`<br><br><br>-----------------------------------------------<br><br>Starting Nmap 7.91 ( https://nmap.org ) at 2021-09-22 10:00 EDT<br>
  Nmap scan report for linux-server (192.168.1.13)<br>
  Host is up (0.0050s latency).<br>
  Not shown: 999 closed ports<br>
  PORT   STATE SERVICE<br>
  22/tcp open  ssh<br>
  |_ssh-hostkey: 1024 SHA256:abcdefghijklmnopqrstuvwxyz1234567890 (RSA)<br>
  |_ssh-hostkey: 256 SHA256:abcdefghijklmnopqrstuvwxyz1234567890 (ECDSA)<br>
  |_ssh-hostkey: 256 SHA256:abcdefghijklmnopqrstuvwxyz1234567890 (ED25519)<br>
  Warning: OSScan results may be unreliable because we could not find at least 1 open and 1 closed port<br>
  Aggressive OS guesses: Linux 4.15 - 5.11 (92%), Linux 3.12 (92%), Linux 4.0 (90%), Linux 4.8 (90%), Linux 4.9 (90%)<br>
  No exact OS matches for host (test conditions non-ideal).<br>
  Network Distance: 1 hop<br><br>

  OS detection performed. Please report any incorrect results at https://nmap.org/submit/ .<br>
  Nmap done: 1 IP address (1 host up) scanned in 13.87 seconds<br><br>
  -----------------------------------------------<br><br><br>`);
          }

          if (x.o === '192.168.1.27') {
            return createStdout(`<br><br><br>-----------------------------------------------<br><br>Starting Nmap 7.91 ( https://nmap.org ) at 2021-09-22 10:10 EDT<br>
  Nmap scan report for windows-workstation (192.168.1.27)<br>
  Host is up (0.0030s latency).<br>
  Not shown: 999 closed ports<br>
  PORT    STATE SERVICE<br>
  135/tcp open  msrpc<br>
  |_rpcinfo: 4 services<br>
  | rpcinfo: Program version    Port<br>
  |  100000 2                   111/tcp  rpcbind<br>
  |  100000 2                   111/udp  rpcbind<br>
  |  100003 2,3,4,7             2049/tcp  nfs<br>
  |  100003 2,3,4,7             2049/udp  nfs<br>
  |_ 100024 1                   41324/tcp  status<br>
  Warning: OSScan results may be unreliable because we could not find at least 1 open and 1 closed port<br>
  Aggressive OS guesses: Microsoft Windows XP SP3 or Windows Server 2003 (97%), Microsoft Windows Server 2003 (96%), Microsoft Windows 7 Professional or Windows 8 (96%), Microsoft Windows 7 Professional (96%), Microsoft Windows 10 (96%)<br>
  No exact OS matches for host (test conditions non-ideal).<br>
  Network Distance: 1 hop<br><br>

  OS detection performed. Please report any incorrect results at https://nmap.org/submit/ .<br>
  Nmap done: 1 IP address (1 host up) scanned in 10.53 seconds<br><br>
  -----------------------------------------------<br><br><br>`);
          }

          if (x.o === '192.168.1.31') {
            return createStdout(`<br><br><br>-----------------------------------------------<br><br>Starting Nmap 7.91 ( https://nmap.org ) at 2021-09-22 10:20 EDT<br>
  Nmap scan report for network printer (192.168.1.31)<br>
  Host is up (0.0070s latency).<br>
  Not shown: 999 closed ports<br>
  PORT    STATE SERVICE<br>
  9100/tcp open  jetdirect<br>
  | fingerprint-strings:<br>
  |   GenericLines:<br>
  |     220 example Printer<br>
  |     ?^<br>
  |     !:v10.2/v2.4.2/HP11-14.1<br>
  |_    generic print job<br>
  Warning: OSScan results may be unreliable because we could not find at least 1 open and 1 closed port<br>
  No exact OS matches for host (test conditions non-ideal).<br>
  Network Distance: 1 hop<br><br>

  OS detection performed. Please report any incorrect results at https://nmap.org/submit/ .<br>
  Nmap done: 1 IP address (1 host up) scanned in 7.39 seconds<br><br>
  -----------------------------------------------<br><br><br>`);
          }

          if (x.o === '192.168.1.44') {
            return createStdout(`<br><br><br>-----------------------------------------------<br><br>Starting Nmap 7.91 ( https://nmap.org ) at 2021-09-22 10:30 EDT<br>
  Nmap scan report for macbook (192.168.1.44)<br>
  Host is up (0.0090s latency).<br>
  Not shown: 999 closed ports<br>
  PORT   STATE SERVICE<br>
  80/tcp open  http<br>
  |_http-favicon: Unknown favicon MD5: 6BCD2C3A6F57A96B2630E34D36BCD2C3<br>
  |_http-title: Site doesn't have a title (text/html).<br>
  |_http-methods: No Allow or Public header in OPTIONS response (status code 200)<br>
  |_http-server-header: nginx/1.21.0<br>
  |_http-title: Welcome to nginx!<br>
  Warning: OSScan results may be unreliable because we could not find at least 1 open and 1 closed port<br>
  Aggressive OS guesses: Apple macOS 10.15.7 (Catalina) (97%), Apple macOS 10.13.0 - 10.15.6 (High Sierra - Catalina) (97%), Apple macOS 10.14.0 (Mojave) (97%), Apple macOS 10.14.4 (Mojave) (97%), Apple macOS 10.15.2 (Catalina) (97%)<br>
  No exact OS matches for host (test conditions non-ideal).<br>
  Network Distance: 1 hop<br><br>

  OS detection performed. Please report any incorrect results at https://nmap.org/submit/ .<br>
  Nmap done: 1 IP address (1 host up) scanned in 7.71 seconds<br><br>
  -----------------------------------------------<br><br><br>`);
          }

          return createStdout('Invalid IP');
        },
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    markExercise() {
      let totalScore = 0;

      if (document.getElementById('input0').textContent === 'Cisco Router') {
        document.getElementById('input0').style.borderColor = '#28C76F';
        document.getElementById('input0').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input0').style.borderColor = '#fd4445';
        document.getElementById('input0').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input1').textContent === 'Linux Server') {
        document.getElementById('input1').style.borderColor = '#28C76F';
        document.getElementById('input1').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input1').style.borderColor = '#fd4445';
        document.getElementById('input1').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input2').textContent === 'Windows Workstation') {
        document.getElementById('input2').style.borderColor = '#28C76F';
        document.getElementById('input2').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input2').style.borderColor = '#fd4445';
        document.getElementById('input2').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input3').textContent === 'Network Printer') {
        document.getElementById('input3').style.borderColor = '#28C76F';
        document.getElementById('input3').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input3').style.borderColor = '#fd4445';
        document.getElementById('input3').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input4').textContent === 'macOS Laptop') {
        document.getElementById('input4').style.borderColor = '#28C76F';
        document.getElementById('input4').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input4').style.borderColor = '#fd4445';
        document.getElementById('input4').style.backgroundColor = '#fd4445';
      }

      if (totalScore === 5) {
        return this.$emit('simulation_exercise_correct', totalScore);
      }

      this.$emit('simulation_exercise_getting_there', totalScore);
    },
  },
  components: {
    draggable,
    Prism,
    VueCommand,
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
#Lgx175726384910572349 {
  .draggable-source--is-dragging {
    width: fit-content !important;
  }
}
</style>
